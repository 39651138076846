<h1 mat-dialog-title>
  {{ this.configurationItem.name }}
</h1>
<div mat-dialog-content>
  <ng-container
    *ngIf="
      this.configurationItem?.more_details?.content?.length !== 0;
      else descOnlyCard
    "
  >
    <ng-container
      *ngIf="this.configurationItem?.more_details?.content?.length === 1"
    >
      <div *ngTemplateOutlet="cardContent"></div>
    </ng-container>
    <ng-container
      *ngIf="this.configurationItem?.more_details?.content?.length !== 1"
    >
      <div class="card-carousel-wrap" data-test="sl_sc_ci_carousel">
        <ds-card-carousel [isSingleCard]="true">
          <div *ngTemplateOutlet="cardContent"></div>
        </ds-card-carousel>
      </div>
    </ng-container>
  </ng-container>
</div>
<mat-dialog-actions fxLayoutAlign="end">
  <button mat-button mat-dialog-close>
    {{ 'forms.btn_close' | translate }}
  </button>
</mat-dialog-actions>

<ng-template #descOnlyCard>
  <mat-card>
    <mat-card-content>
      <div
        class="ci-details"
        *ngIf="
          this.configurationItem?.more_details?.description ??
          this.configurationItem?.description
        "
        [innerHtml]="
          this.configurationItem?.more_details?.description ??
            this.configurationItem?.description | slScDescriptionDecoration
        "
      ></div>
    </mat-card-content>
  </mat-card>
</ng-template>

<ng-template #cardContent>
  <mat-card
    *ngFor="let content of this.configurationItem?.more_details?.content"
  >
    <img
      mat-card-image
      *ngIf="content.media_type === MediaType.Image"
      src="{{ content.media_uri }}"
      alt="{{ content?.description }}"
      data-test="sl_sc_ci_image"
    />

    <video
      *ngIf="content.media_type === MediaType.Video"
      controls
      data-test="sl_sc_ci_video"
      (play)="playVideo()"
      #videoPlayer
      class="ci-img-video"
    >
      <source src="{{ content.media_uri }}" type="video/mp4" />
      {{ 'sales_shared.common.video_not_supported' | translate }}
    </video>

    <mat-card-content>
      <div
        class="ci-details"
        *ngIf="content.description ?? this.configurationItem?.description"
        [innerHtml]="
          content.description ?? this.configurationItem?.description
            | slScDescriptionDecoration
        "
      ></div>
    </mat-card-content>
  </mat-card>
</ng-template>
