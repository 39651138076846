import { createAction, props } from '@ngrx/store';
import {
  ConfigurationCode,
  ContactForm,
  Currencies,
  Form,
  OnlineCode,
  ProblemDetails,
  UserGuide,
} from '@sales-libs/sc-summary/data-access';

export namespace SlScSummaryActions {
  export const getUserConfiguration = createAction(
    '[Summary_UserConfiguration] GET_USER_CONFIGURATION',
    props<{
      guideId: number;
      guid: string;
      language: string;
      currencyCode?: string;
    }>(),
  );
  export const getUserConfigurationSuccess = createAction(
    '[Summary_UserConfiguration] GET_USER_CONFIGURATION_SUCCESS',
    props<{ userConfiguration: any }>(),
  );
  export const getUserConfigurationError = createAction(
    '[Summary_UserConfiguration] GET_USER_CONFIGURATION_ERROR',
    props<{ payload: ProblemDetails }>(),
  );

  export const getConfigurationCode = createAction(
    '[Summary_ConfigurationCode] GET_CONFIGURATION_CODE',
    props<{
      guideId: number;
      guid: string;
      language: string;
      currencyCode: string;
    }>(),
  );
  export const getConfigurationCodeSuccess = createAction(
    '[Summary_ConfigurationCode] GET_CONFIGURATION_CODE_SUCCESS',
    props<{ configurationCode: ConfigurationCode }>(),
  );
  export const getConfigurationCodeError = createAction(
    '[Summary_ConfigurationCode] GET_CONFIGURATION_CODE_ERROR',
    props<{ payload: ProblemDetails }>(),
  );

  export const getSharedUserGuide = createAction(
    '[Summary_SharedUserGuide] GET_SHARED_USER_GUIDE',
    props<{ configurationCode: string }>(),
  );
  export const getSharedUserGuideSuccess = createAction(
    '[Summary_SharedUserGuide] GET_SHARED_USER_GUIDE_SUCCESS',
    props<{ userGuide: UserGuide }>(),
  );
  export const getSharedUserGuideError = createAction(
    '[Summary_SharedUserGuide] GET_SHARED_USER_GUIDE_ERROR',
    props<{ payload: ProblemDetails }>(),
  );

  export const getContactFormDetails = createAction(
    '[Summary_ContactForm] GET_CONTACT_FORM_DETAILS',
    props<{ language: string }>(),
  );
  export const getContactFormDetailsSuccess = createAction(
    '[Summary_ContactForm] GET_CONTACT_FORM_DETAILS_SUCCESS',
    props<{ form: Form }>(),
  );
  export const getContactFormDetailsError = createAction(
    '[Summary_ContactForm] GET_CONTACT_FORM_DETAILS_ERROR',
    props<{ payload: ProblemDetails }>(),
  );

  export const submitContactForm = createAction(
    '[Summary_ContactForm] SUBMIT_CONTACT_FORM',
    props<{
      guideId: number;
      guid: string;
      language: string;
      contactForm: ContactForm;
    }>(),
  );
  export const submitContactFormSuccess = createAction(
    '[Summary_ContactForm] SUBMIT_CONTACT_FORM_SUCCESS',
  );
  export const submitContactFormError = createAction(
    '[Summary_ContactForm] SUBMIT_CONTACT_FORM_ERROR',
    props<{ payload: ProblemDetails }>(),
  );

  export const getCurrencies = createAction(
    '[Summary] GET_CURRENCIES',
    props<{ guideId: number }>(),
  );
  export const getCurrenciesSuccess = createAction(
    '[Summary] GET_CURRENCIES_SUCCESS',
    props<{ currencies: Currencies }>(),
  );
  export const getCurrenciesError = createAction(
    '[Summary] GET_CURRENCIES_ERROR',
    props<{ payload: ProblemDetails }>(),
  );

  export const toggleSoMoreDetails = createAction(
    '[Summary] TOGGLE_SO_MORE_DETAILS',
  );
  export const resetToggleSoMoreDetails = createAction(
    '[Summary] RESET_TOGGLE_SO_MORE_DETAILS',
  );

  export const getOnlineCode = createAction(
    '[Summary] GET_ONLINE_CODE',
    props<{
      guideId: number;
      guid: string;
    }>(),
  );
  export const getOnlineCodeSuccess = createAction(
    '[Summary] GET_ONLINE_CODE_SUCCESS',
    props<{ onlineCode: OnlineCode }>(),
  );
  export const getOnlineCodeError = createAction(
    '[Summary] GET_ONLINE_CODE_ERROR',
    props<{ payload: ProblemDetails }>(),
  );
}
