import { CommonModule } from '@angular/common';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DsCardCarouselComponent } from '@design-system/components/card-carousel';
import { TranslateModule } from '@ngx-translate/core';
import { ConfigurationItem } from '@sales-libs/sc-summary/data-access';
import {
  MediaType,
  SlScDescriptionDecorationPipe,
} from '@sales-libs/shared/util';

export const imports = [
  CommonModule,
  TranslateModule,
  SlScDescriptionDecorationPipe,
  MatCardModule,
  MatDialogModule,
  DsCardCarouselComponent,
  FlexModule,
  MatButtonModule,
];

@Component({
  selector: 'sl-sc-configuration-item-details-dialog',
  standalone: true,
  imports,
  templateUrl: './configuration-item-details-dialog.component.html',
  styleUrls: ['./configuration-item-details-dialog.component.scss'],
})
export class SlScConfigurationItemDetailsDialogComponent {
  @ViewChild('videoPlayer') videoPlayer: ElementRef;
  readonly MediaType = MediaType;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public configurationItem: ConfigurationItem,
  ) {}

  playVideo() {
    this.videoPlayer?.nativeElement.play();
  }
}
