<div class="summary-screen">
  <ds-spotlight id="sl-sc-welcome-spotlight" *ngIf="displaySpotlight">
    <h3>{{ 'sales_shared.summary.spotlight_main_title' | translate }}</h3>
    {{ 'sales_shared.summary.spotlight_main_text' | translate }}
  </ds-spotlight>

  <div *ngIf="!stepName && isSC" class="logo-container">
    <sl-shared-sc-product-line-icons
      [productLineName]="scProductLineName"
      [selectedModelName]="this.userConfiguration?.selected_model"
      [urlFriendlyProductLineEnum]="urlFriendlyProductLineEnum"
      [environmentLink]="scUrl"
      (startNewConfiguration)="handleStartNewConfiguration()"
    ></sl-shared-sc-product-line-icons>
  </div>

  <div
    class="summary-upper-wrap"
    fxLayout="row wrap"
    fxLayoutAlign="space-between"
  >
    <ng-container *ngIf="(isLoading$ | async) === false; else priceLoading">
      <h2>
        <ng-container [ngTemplateOutlet]="modelName" />
      </h2>
    </ng-container>
    @if (displayPrice) {
      <div
        class="summary-currency-wrap"
        *ngIf="supportedCurrencies?.data as supportedCurrencies"
      >
        <ng-container
          *ngIf="(isLoading$ | async) === false; else dropdownLoading"
        >
          <mat-select
            [(value)]="selectedCurrency"
            data-test="sc_currency_dropdown"
            [disabled]="supportedCurrencies.length <= 1"
            (selectionChange)="changeSelectedCurrency(selectedCurrency)"
          >
            <mat-option
              *ngFor="let supportedCurrency of supportedCurrencies"
              [value]="supportedCurrency.code"
            >
              {{ supportedCurrency.code ?? '' | slScCurrencySymbol }}
              {{ supportedCurrency.name }}
            </mat-option>
          </mat-select>
        </ng-container>
      </div>
    }
  </div>
  <div
    fxLayout="row wrap"
    fxLayoutAlign="space-between start"
    class="summary-upper-wrap"
  >
    <div
      fxLayoutAlign="center center"
      fxFlex="65%"
      fxFlex.lt-md="100%"
      class="fixed-height summary-carousel"
    >
      <ds-image-carousel
        [slides]="
          slides
            ? slides
            : [
                {
                  src: '../../assets/img/placeholder-img.jpg'
                }
              ]
        "
        [height]="'100%'"
        class="full-width full-height"
        [autoSlide]="0"
      ></ds-image-carousel>
    </div>

    <div class="summary-upper-wrap__info" fxFlex="30%" fxFlex.lt-md="100%">
      <p>{{ 'sales_shared.summary.your_solution' | translate }}</p>

      <ng-container *ngIf="(isLoading$ | async) === false; else priceLoading">
        <h2
          fxLayout="row wrap"
          fxLayoutAlign="space-between baseline"
          data-cy="model-name"
        >
          <ng-container [ngTemplateOutlet]="modelName" />
          @if (displayPrice) {
            <ng-container
              *ngIf="userConfiguration && userConfiguration.currency_code"
            >
              <span
                *ngIf="
                  userConfiguration.display_unit_prices &&
                  userConfiguration.model_price
                "
                >{{
                  userConfiguration.model_price.total_price
                    | currency: userConfiguration.currency_code
                }}</span
              >
            </ng-container>
          }
        </h2>
      </ng-container>

      @if (displayPrice) {
        <div
          fxLayout="column"
          fxLayout.lt-sm="row"
          fxLayoutAlign="start"
          fxLayoutAlign.lt-sm="space-between baseline"
        >
          <p class="summary-upper-wrap__price">
            {{ 'sales_shared.summary.total_price' | translate }}
          </p>
          <ng-container
            *ngIf="(isLoading$ | async) === false; else priceLoading"
          >
            <h2
              class="summary-configuration__price-weight-number"
              *ngIf="userConfiguration && userConfiguration.currency_code"
              data-cy="total-price"
            >
              {{
                userConfiguration.total_price
                  | currency: userConfiguration.currency_code
              }}
            </h2>
          </ng-container>
        </div>
      }

      <ng-container *ngIf="userConfiguration; else descriptionLoading">
        <ul *ngIf="userConfiguration.model_description" class="model-details">
          <li
            *ngFor="let description of userConfiguration.model_description"
            [innerHtml]="description | slScDescriptionDecoration"
          ></li>
        </ul>
      </ng-container>

      <div fxLayout="row wrap" class="summary-upper-wrap__info-btns">
        @if (
          !isSC &&
          this.scProductLineName === urlFriendlyProductLineEnum.ShortTimber
        ) {
        } @else {
          <ng-container
            *ngIf="(isLoading$ | async) === false; else dropdownLoading"
          >
            <button
              mat-stroked-button
              type="button"
              [matMenuTriggerFor]="shareMenu"
              class="sc-summary-menu-btn"
              data-test="sc-summary-menu"
              data-cy="sc-summary-menu"
            >
              <mat-icon>share</mat-icon>
              {{ 'sales_shared.summary.button_share' | translate }}
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
            <mat-menu #shareMenu="matMenu" [xPosition]="'before'">
              <button
                mat-menu-item
                disableRipple="true"
                (click)="openExportDialog()"
                data-cy="sc-summary-menu-item"
              >
                <mat-icon>download</mat-icon>
                {{ 'sales_shared.summary.button_export' | translate }}
              </button>
              <button
                mat-menu-item
                disableRipple="true"
                (click)="onCopy()"
                data-cy="sc-summary-menu-item"
                [gaClickListener]="Analytics.UrlShare"
              >
                <mat-icon class="link-icon">link</mat-icon>
                {{ 'sales_shared.common.copy_link' | translate }}
              </button>
              <button
                mat-menu-item
                disableRipple="true"
                (click)="$event.preventDefault(); sendEmail()"
                data-cy="sc-summary-menu-item"
                [gaClickListener]="Analytics.UrlShare"
              >
                <mat-icon>email</mat-icon>
                {{ 'general.email' | translate }}
              </button>
            </mat-menu>
          </ng-container>
        }
        <ng-container
          *ngIf="
            (this.scProductLineName === urlFriendlyProductLineEnum.TailLift ||
              this.scProductLineName ===
                urlFriendlyProductLineEnum.ShortTimber ||
              this.scProductLineName ===
                urlFriendlyProductLineEnum.TruckMountedForklifts) &&
            displaySpotlight
          "
        >
          @if ((isLoading$ | async) === false) {
            <button
              mat-raised-button
              color="accent"
              (click)="openContactFormDialog()"
              data-test="sc-summary-button"
              data-cy="sc-quote-button"
              [spotlight]="requestQuote"
            >
              <mat-icon>contact_mail</mat-icon>
              {{ 'sales_shared.summary.button_contact_form' | translate }}
            </button>

            <ds-spotlight
              #requestQuote
              position="left"
              [tags]="['position']"
              id="request-quote-spotlight"
            >
              {{ 'sales_shared.summary.spotlight_contact_form' | translate }}
            </ds-spotlight>
          } @else {
            <ng-container *ngTemplateOutlet="dropdownLoading"></ng-container>
          }
        </ng-container>
        <ng-container
          *ngIf="
            (this.scProductLineName === urlFriendlyProductLineEnum.TailLift ||
              this.scProductLineName ===
                urlFriendlyProductLineEnum.ShortTimber ||
              this.scProductLineName ===
                urlFriendlyProductLineEnum.TruckMountedForklifts) &&
            !displaySpotlight
          "
        >
          <button
            mat-raised-button
            color="accent"
            (click)="openContactFormDialog()"
            data-test="sc-summary-button"
            data-cy="sc-quote-button"
          >
            <mat-icon>contact_mail</mat-icon>
            {{ 'sales_shared.summary.button_contact_form' | translate }}
          </button>
        </ng-container>
      </div>

      <div
        *ngIf="isSC"
        fxLayout="row wrap"
        class="summary-upper-wrap__online-code"
      >
        @if ((isLoading$ | async) === false) {
          <button
            mat-stroked-button
            (click)="generateAndDisplayOnlineCode()"
            data-test="sc-code-button"
            data-cy="sc-code-button"
          >
            <mat-icon>tag</mat-icon>
            {{ 'sales_shared.summary.button_online_code' | translate }}
          </button>
          @if (onlineCode) {
            <button
              mat-button
              disableRipple="true"
              class="sc-summary-copy-code-btn"
              (click)="onOnlineCodeCopy()"
              [gaClickListener]="Analytics.CopyOnlineCode"
            >
              <span>{{ onlineCode }}</span>
              <mat-icon>content_copy</mat-icon>
            </button>
          }
        } @else {
          <ng-container *ngTemplateOutlet="dropdownLoading"></ng-container>
        }
      </div>
    </div>
  </div>
  <div class="summary-links">
    <mat-divider></mat-divider>
    <div fxLayout="row wrap" [fxLayoutGap.gt-xs]="2 | dsSpacing">
      <mat-card
        class="summary-links__card"
        fxLayout="column wrap"
        fxLayoutAlign="space-between"
        fxFlex.lt-sm="100%"
      >
        <mat-card-content>
          <div fxLayout="row">
            <mat-icon class="summary-links__icon">info</mat-icon>
            <div>
              <h3>
                {{ 'sales_shared.summary.product_website_title' | translate }}
              </h3>
              <p>
                {{ 'sales_shared.summary.product_website_text' | translate }}
              </p>
            </div>
          </div>
          <a
            [href]="userConfiguration.external_uri"
            target="_blank"
            rel="noopener noreferrer"
            class="text-right"
            *ngIf="userConfiguration"
            data-cy="palfinger-link"
          >
            {{ 'sales_shared.summary.product_website_link' | translate }}
          </a>
        </mat-card-content>
      </mat-card>
      <mat-card
        class="summary-links__card"
        fxLayout="column wrap"
        fxLayoutAlign="space-between"
        fxFlex.lt-sm="100%"
        *ngIf="
          scProductLineName === urlFriendlyProductLineEnum.TailLift ||
          scProductLineName === urlFriendlyProductLineEnum.TimberCrane
        "
      >
        @if (this.scProductLineName === urlFriendlyProductLineEnum.TailLift) {
          <mat-card-content>
            <div fxLayout="row">
              <mat-icon class="summary-links__icon">fact_check</mat-icon>
              <div>
                <h3>
                  {{
                    'sales_shared.summary.feasibility_study_title' | translate
                  }}
                </h3>
                <p>
                  {{
                    'sales_shared.summary.feasibility_study_text' | translate
                  }}
                </p>
              </div>
            </div>
            <a
              href="https://tail-lift-study.palfinger.com/project"
              target="_blank"
              rel="noopener noreferrer"
              class="text-right"
              data-cy="palfinger-link"
            >
              {{ 'sales_shared.summary.feasibility_study_link' | translate }}
            </a>
          </mat-card-content>
        } @else if (
          this.scProductLineName === urlFriendlyProductLineEnum.TimberCrane
        ) {
          <mat-card-content>
            <div fxLayout="row">
              <mat-icon
                class="summary-links__icon"
                fontSet="pd"
                fontIcon="pd-documents"
              ></mat-icon>
              <div>
                <h3>
                  {{ 'sales_shared.summary.brochure_title' | translate }}
                </h3>
                <p>
                  {{ 'sales_shared.summary.brochure_text' | translate }}
                </p>
              </div>
            </div>
            <a
              [href]="userConfiguration?.brochure_uri"
              rel="noopener noreferrer"
              class="text-right"
              *ngIf="userConfiguration"
              target="_blank"
            >
              {{ 'sales_shared.summary.download_brochure' | translate }}
            </a>
          </mat-card-content>
        }
      </mat-card>
    </div>
  </div>
  <div class="summary-configuration">
    <mat-divider></mat-divider>
    <h2 fxLayoutAlign="start" fxLayoutAlign.lt-sm="center">
      {{ 'sales_shared.summary.configuration' | translate }}
    </h2>
    <a
      (click)="this.isExpanded ? closeAllPanels() : openAllPanels()"
      fxLayoutAlign="end center"
      data-test="toggle_expansion_panels"
    >
      {{
        (this.isExpanded
          ? 'sales_shared.common.collapse_all'
          : 'sales_shared.common.expand_all'
        ) | translate
      }}
    </a>

    <div
      fxLayout="row"
      fxLayoutAlign="flex-end"
      fxLayoutAlign.lt-md="flex-start"
      *ngIf="userConfiguration?.enable_descriptions"
    >
      <mat-slide-toggle
        data-test="toggle-more-details"
        (change)="toggleMoreDetails()"
        [checked]="true"
        >{{
          'sales_shared.summary.button_toggle_more_details' | translate
        }}</mat-slide-toggle
      >
    </div>
    <div class="summary-configuration__lists">
      <div
        *ngIf="userConfiguration?.data as data"
        fxLayout="column"
        fxLayoutGap="20"
      >
        <div *ngFor="let section of data">
          <mat-accordion>
            <mat-expansion-panel
              [expanded]="isExpanded"
              data-cy="summary-accordion"
            >
              <mat-expansion-panel-header
                fxLayout.xs="column wrap"
                fxLayoutAlign="center center"
              >
                <mat-panel-title>
                  <h4>{{ section.name }}</h4>
                  <a
                    *ngIf="section.type === SectionType.Configuration"
                    class="h4 edit-link"
                    (click)="goToConfigurationSection($event, section.name)"
                  >
                    <mat-icon class="edit-link-icon">edit</mat-icon>
                  </a>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <ng-container
                *ngFor="let category of section?.sales_option_categories"
              >
                <div
                  class="summary-configuration__salesoption"
                  *ngFor="
                    let configuration_item of category?.configuration_items
                  "
                  fxLayout="row wrap"
                  fxLayout.lt-sm="column"
                  fxLayoutAlign="space-between baseline"
                  [fxLayoutGap]="0.2 | dsSpacing"
                >
                  <div
                    [fxFlex]="isSoMoreDetailsVisible ? 25 : 40"
                    fxLayoutAlign="flex-start center"
                  >
                    <span
                      *ngIf="
                        configuration_item.name !== undefined &&
                        configuration_item.name !== null
                      "
                      class="cut-text-lt-md"
                      [matTooltip]="configuration_item.name"
                      [matTooltipDisabled]="
                        (configuration_item.name | translate).length < 17 ||
                        !media.isActive('lt-md')
                      "
                      >{{ configuration_item.name }}</span
                    >
                    <span
                      matTooltip="{{
                        'sales_shared.configuration.sales_option_tooltip_text'
                          | translate
                      }}"
                      [matTooltipPosition]="'above'"
                    >
                      <button
                        mat-icon-button
                        *ngIf="
                          configuration_item.more_details &&
                          configuration_item.more_details?.description !== ''
                        "
                        data-test="so_info_btn"
                        (click)="openInfoDialog(configuration_item)"
                      >
                        <mat-icon>info_outlined</mat-icon>
                      </button>
                    </span>
                  </div>
                  <div
                    fxLayout="row wrap"
                    fxLayout.lt-sm="column"
                    [fxFlex]="isSoMoreDetailsVisible ? 25 : 60"
                    [fxLayoutAlign]="
                      isSoMoreDetailsVisible ? 'flex-start' : 'flex-end'
                    "
                    class="summary-configuration__salesoption--value"
                  >
                    <p>
                      <strong>{{ configuration_item.value }}</strong>
                    </p>

                    @if (displayPrice) {
                      <ng-container
                        *ngIf="
                          (isLoading$ | async) === false;
                          else priceLoading
                        "
                      >
                        <p
                          *ngIf="
                            userConfiguration.display_unit_prices &&
                            configuration_item.price &&
                            configuration_item.price.currency_code
                          "
                          fxLayoutAlign.lt-sm="end"
                        >
                          &nbsp;{{
                            configuration_item.price.total_price
                              | currency: configuration_item.price.currency_code
                          }}
                        </p>
                      </ng-container>
                    }
                  </div>
                  <div
                    *ngIf="
                      userConfiguration?.enable_descriptions &&
                      isSoMoreDetailsVisible
                    "
                    [fxFlex]="isSoMoreDetailsVisible ? 45 : 0"
                    class="summary-configuration__salesoption--description"
                  >
                    <span
                      [innerHtml]="
                        configuration_item.description
                          | slScDescriptionDecoration
                      "
                    ></span>
                  </div>
                </div>
              </ng-container>
              <mat-divider></mat-divider>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>

  <div class="summary-details" *ngIf="userConfiguration">
    <h2 fxLayoutAlign="start" fxLayoutAlign.lt-sm="center">
      {{ 'sales_shared.summary.item_details' | translate }}
    </h2>

    <div
      fxLayout="row wrap"
      fxLayoutAlign="flex-start"
      fxLayoutAlign.lt-sm="center"
      class="summary-details__container"
    >
      <ng-container *ngFor="let section of userConfiguration.data">
        <ng-container *ngFor="let category of section?.sales_option_categories">
          <ng-container
            *ngFor="let configuration_item of category?.configuration_items"
          >
            <mat-card
              *ngFor="let content of configuration_item.more_details?.content"
              class="summary-details__card"
            >
              <img
                mat-card-image
                *ngIf="content.media_type === MediaType.Image"
                src="{{ content.media_uri }}"
                alt="{{ content?.description }}"
              />
              <mat-card-content>
                <h5>
                  {{ configuration_item.name | uppercase }} -
                  <strong>{{ configuration_item.value }}</strong>
                </h5>
                <div
                  [innerHTML]="
                    configuration_item.more_details?.description
                      | slScDescriptionDecoration
                  "
                ></div>
              </mat-card-content>
            </mat-card>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div class="summary-sum">
    <mat-card *ngIf="userConfiguration" class="summary-configuration__price">
      <mat-card-content class="summary-configuration__price-wrap">
        <div
          class="summary-configuration__price-total-wrap"
          *ngIf="(isLoading$ | async) === false; else priceLoading"
        >
          <p>{{ 'sales_shared.summary.your_solution' | translate }}</p>
          <p [style.visibility]="displayPrice ? 'visible' : 'hidden'">
            {{ 'sales_shared.summary.total_price' | translate }}
          </p>
          <h2 data-cy="model-name">
            <ng-container [ngTemplateOutlet]="modelName" />
          </h2>
          <h2
            *ngIf="displayPrice && userConfiguration.currency_code"
            data-cy="total-price"
          >
            {{
              userConfiguration.total_price
                | currency: userConfiguration.currency_code
            }}
          </h2>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card
      *ngIf="
        (scProductLineName === urlFriendlyProductLineEnum.TimberCrane ||
          scProductLineName === urlFriendlyProductLineEnum.ShortTimber) &&
        modelDetails.length > 0
      "
      class="summary-configuration__price"
    >
      <mat-card-content class="summary-configuration__price-wrap">
        <div
          class="summary-configuration__price-weight-wrap"
          [ngClass]="{ single: modelDetails.length === 1 }"
          *ngIf="(isLoading$ | async) === false; else priceLoading"
        >
          <ng-container *ngFor="let details of modelDetails">
            <p>
              {{ details.weight_label }}
            </p>
            <p [style.visibility]="displayPrice ? 'visible' : 'hidden'">
              {{ details.price_label }}
            </p>
          </ng-container>
          <ng-container *ngFor="let details of modelDetails">
            <h4>
              {{ details.weight?.formatted_weight }}
              {{ details.weight?.unit }}
            </h4>
            <h4 [style.visibility]="displayPrice ? 'visible' : 'hidden'">
              {{
                details.price?.total_price
                  | currency: details.price?.currency_code ?? ''
              }}
            </h4>
          </ng-container>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  @if (isSC) {
    <div class="summary-next">
      <mat-divider></mat-divider>
      <h2 fxLayoutAlign="start" fxLayoutAlign.lt-sm="center">
        {{ 'sales_shared.summary.next.main_title' | translate }}
      </h2>

      <div
        class="summary-next__container"
        fxLayout="row wrap"
        [fxLayoutGap.gt-md]="1 | dsSpacing"
        fxLayoutAlign.lt-lg="space-between"
        fxLayoutAlign.lt-sm="center"
      >
        <mat-card class="summary-next__card">
          <mat-card-content>
            <div fxLayoutAlign="center center" class="summary-next__icon--wrap">
              <mat-icon class="summary-next__icon">share</mat-icon>
            </div>
            <div
              fxLayout="column"
              fxLayoutAlign="center center"
              class="summary-next__text--wrap"
            >
              <h3>{{ 'sales_shared.summary.button_share' | translate }}</h3>
              <p>{{ 'sales_shared.summary.next.share_text' | translate }}</p>
              <button
                mat-stroked-button
                type="button"
                [matMenuTriggerFor]="shareMenu"
                class="sc-summary-menu-btn full-width"
                data-test="sc-summary-menu"
              >
                {{ 'sales_shared.summary.button_share' | translate }}
              </button>
              <mat-menu #shareMenu="matMenu" [xPosition]="'before'">
                <button
                  mat-menu-item
                  disableRipple="true"
                  (click)="openExportDialog()"
                >
                  <mat-icon>download</mat-icon>
                  {{ 'sales_shared.summary.button_export' | translate }}
                </button>
                <button mat-menu-item disableRipple="true" (click)="onCopy()">
                  <mat-icon class="link-icon">link</mat-icon>
                  {{ 'sales_shared.common.copy_link' | translate }}
                </button>
                <button
                  mat-menu-item
                  disableRipple="true"
                  (click)="$event.preventDefault(); sendEmail()"
                >
                  <mat-icon>email</mat-icon>
                  {{ 'general.email' | translate }}
                </button>
              </mat-menu>
            </div>
          </mat-card-content>
        </mat-card>
        <ng-container
          *ngIf="this.scProductLineName === urlFriendlyProductLineEnum.TailLift"
        >
          <mat-card class="summary-next__card">
            <mat-card-content>
              <div
                fxLayoutAlign="center center"
                class="summary-next__icon--wrap"
              >
                <mat-icon class="summary-next__icon">contact_mail</mat-icon>
              </div>
              <div
                fxLayout="column"
                fxLayoutAlign="center center"
                class="summary-next__text--wrap"
              >
                <h3>
                  {{ 'sales_shared.summary.next.quote_title' | translate }}
                </h3>
                <p>
                  {{ 'sales_shared.summary.next.quote_text' | translate }}
                </p>
                <button
                  mat-stroked-button
                  (click)="openContactFormDialog()"
                  data-test="sc-summary-button"
                  class="full-width"
                >
                  {{ 'sales_shared.summary.button_contact_form' | translate }}
                </button>
              </div>
            </mat-card-content>
          </mat-card>
        </ng-container>
        <ng-container
          *ngIf="
            this.scProductLineName === urlFriendlyProductLineEnum.TailLift ||
            this.scProductLineName === urlFriendlyProductLineEnum.TimberCrane ||
            this.scProductLineName === urlFriendlyProductLineEnum.ShortTimber
          "
        >
          <mat-card class="summary-next__card">
            <mat-card-content
              *ngIf="
                this.scProductLineName === urlFriendlyProductLineEnum.TailLift
              "
            >
              <div
                fxLayoutAlign="center center"
                class="summary-next__icon--wrap"
              >
                <mat-icon class="summary-next__icon">fact_check</mat-icon>
              </div>
              <div
                fxLayout="column"
                fxLayoutAlign="center center"
                class="summary-next__text--wrap"
              >
                <h3>
                  {{
                    'sales_shared.summary.feasibility_study_title' | translate
                  }}
                </h3>
                <p>
                  {{
                    'sales_shared.summary.feasibility_study_text' | translate
                  }}
                </p>
                <a
                  href="https://tail-lift-study.palfinger.com/project"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="full-width"
                  mat-stroked-button
                >
                  {{
                    'sales_shared.summary.feasibility_study_link' | translate
                  }}
                </a>
              </div>
            </mat-card-content>
            <mat-card-content
              *ngIf="
                this.scProductLineName ===
                  urlFriendlyProductLineEnum.TimberCrane ||
                this.scProductLineName ===
                  urlFriendlyProductLineEnum.ShortTimber
              "
            >
              <div
                fxLayoutAlign="center center"
                class="summary-next__icon--wrap"
              >
                <mat-icon
                  class="summary-next__icon"
                  fontSet="pd"
                  fontIcon="pd-documents"
                ></mat-icon>
              </div>
              <div
                fxLayout="column"
                fxLayoutAlign="center center"
                class="summary-next__text--wrap"
              >
                <h3>
                  {{ 'sales_shared.summary.brochure_title' | translate }}
                </h3>
                <p>
                  {{ 'sales_shared.summary.brochure_text' | translate }}
                </p>
                <a
                  [href]="userConfiguration?.brochure_uri"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="full-width"
                  mat-stroked-button
                >
                  {{ 'sales_shared.summary.download_brochure' | translate }}
                </a>
              </div>
            </mat-card-content>
          </mat-card>
        </ng-container>
        <mat-card class="summary-next__card">
          <mat-card-content>
            <div fxLayoutAlign="center center" class="summary-next__icon--wrap">
              <mat-icon class="summary-next__icon">info</mat-icon>
            </div>
            <div
              fxLayout="column"
              fxLayoutAlign="center center"
              class="summary-next__text--wrap"
            >
              <h3>
                {{
                  'sales_shared.summary.next.product_details_title' | translate
                }}
              </h3>
              <p>
                {{
                  'sales_shared.summary.next.product_details_text' | translate
                }}
              </p>
              <a
                [href]="userConfiguration.external_uri"
                target="_blank"
                rel="noopener noreferrer"
                class="full-width"
                mat-stroked-button
                *ngIf="userConfiguration"
              >
                {{ 'sales_shared.common.learn_more' | translate }}
              </a>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  }
  @if (isSC && scSelectedLanguage) {
    <sl-shared-sc-footer [language]="scSelectedLanguage"></sl-shared-sc-footer>
  }

  <ng-template #priceLoading>
    <ds-loading-placeholder
      [width]="10 | dsSpacing"
      [height]="2 | dsSpacing"
    ></ds-loading-placeholder>
  </ng-template>

  <ng-template #descriptionLoading>
    <ds-loading-placeholder
      [width]="10 | dsSpacing"
      [height]="8 | dsSpacing"
    ></ds-loading-placeholder>
  </ng-template>

  <ng-template #dropdownLoading>
    <ds-loading-placeholder
      [width]="7 | dsSpacing"
      [height]="2 | dsSpacing"
    ></ds-loading-placeholder>
  </ng-template>
</div>

<ng-template #modelName>
  <ng-container [ngSwitch]="scProductLineName">
    @if (userConfiguration?.selected_model) {
      <span>{{ userConfiguration.selected_model }}</span>
    } @else {
      <ng-container *ngSwitchCase="urlFriendlyProductLineEnum.TimberCrane">
        {{ 'sales_shared.common.model_name.timber_crane' | translate }}
      </ng-container>
      <ng-container *ngSwitchCase="urlFriendlyProductLineEnum.ShortTimber">
        {{ 'sales_shared.common.model_name.short_timber' | translate }}
      </ng-container>
      <ng-container *ngSwitchDefault>
        {{ 'sales_shared.common.model_name.default' | translate }}
      </ng-container>
    }
  </ng-container>
</ng-template>
